import { defineStore } from "pinia";
import { ElMessage } from "element-plus";
import { cloneDeep } from "lodash";
import {
  getApiSection,
  getApiWaterLevelRate,
  getApiMultiStationWaterLevelRate,
  getApiWaterReport,
  getApiRealTimeMonitoring,
  getApiDataSectionalFlow,
  getRLineAnalysisOrder,
} from "@/api/echarts";
import waterReport from "@/hooks/waterReport"; //水情预报
import multiStationWaterLevelRate from "@/hooks/multiStationWaterLevelRate"; // 多站
import section from "@/hooks/section"; // 断面
import lineAnalysis from "@/assets/json/lineAnalysis.json"; // 代表线表格数据
import velocityFlow from "@/hooks/velocityFlow";
import {
  formatSection,
  formatSectionFlow,
  formatWaterReport,
  formatMultiStationWaterLevelRate,
  formatVelocityFlow,
  formatErrorOption,
} from "@/utils/formatEchartOptions.js";
const echartsData = defineStore("echartsData", {
  state: () => ({
    sectionOptions: {}, // 断面图
    waterSlider: {
      min: "", // 最小值
      max: "", // 最大值
      watervalue: "", // 当前值
    }, // 断面图水位值
    velocityFlowOptions: [], // 流速图
    waterLevelRateOptions: {}, // 水位流量图
    multiStationWaterLevelRateOptions: [], // 多站水位流量
    waterReportOptions: {}, //水情预报
    realTimeMonitoringOptions: {}, //实时监控数据
    offlineDataZouHang: [
      // {
      //   currentTraffic: 0, // 当前流量
      //   sectionalArea: 0,// 断面面积
      //   averageVelocity: 0, // 平均流速
      // }
    ], // 走航离线展示数据
    cloudData: {}, // 流畅数据
    nowDataZouhang: {
      currentTraffic: 0, // 当前流量
      sectionalArea: 0, // 断面面积
      averageVelocity: 0, // 平均流速
    }, // 当前走航离线实时展示数据
    sectionFlowOptions: {}, // 断面流速数据
    sectionPoint: [], // 断面点数据
    data: [
      {
        x: 20,
        y: 20,
      },
      {
        x: 30,
        y: 30,
      },
      {
        x: 40,
        y: 40,
      },
    ],
    delegateTableData: [], // 代表线表格数据
    delegateTableColumns: [], // 代表线表格
    delegateTableTotal: 0,
    direction: "", // slider滑块方向
    page: {
      pageSize: 100,
      pageNumber: 1,
    }, // 表格分页
    orderBy: {
      field: "ru",
      direction: "up",
    }, //表格排序
    errorOptions: {}, // 系统误差图表配置
    sectionXData: [], // 断面x轴数据
  }),
  actions: {
    // 获取断面数据
    async getSectionOptions(isOnline, parmas, no, model) {
      try {
        if (isOnline) {
          const res = await getApiSection(parmas);
          if (res.code == 200) {
            this.sectionXData = res.data.series[0].data.map((item) => {
              return item[0];
            });
            let delegateLineData = [];
            // 如果序号不存在或者为0 默认取数组第一条数据
            if (no) {
              delegateLineData = this.delegateTableData.filter(
                (item) => item.no == no
              );
            } else {
              delegateLineData = this.delegateTableData;
            }

            // model 1-垂直-起点距 0-水平-高程
            if (model == 1) {
              this.waterSlider.min = res.data.mangerBoard[0][0][0];
              this.waterSlider.max = res.data.mangerBoard[1][0][0];
              this.waterSlider.watervalue = delegateLineData[0].distance;
              this.setErrOptions();
            } else if (model == 0) {
              this.waterSlider.min = res.data.mangerBoard[0][0][1];
              this.waterSlider.max = res.data.mangerBoard[0][1][1];
              this.waterSlider.watervalue = delegateLineData[0].distance;
            }

            this.sectionOptions = formatSection(
              cloneDeep(res.data),
              this.delegateTableData,
              no,
              model
            );
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          if (this.delegateTableData && this.delegateTableData.length) {
            this.waterSlider.min = section.data.series[0].minY;
            this.waterSlider.max = section.data.series[0].maxY;
            this.waterSlider.watervalue = section.data.series[0].watermaxY;
            this.sectionOptions = formatSection(
              section.data,
              this.delegateTableData
            );
          } else {
            this.waterSlider.min = section.data.series[0].minY;
            this.waterSlider.max = section.data.series[0].maxY;
            this.waterSlider.watervalue = section.data.series[0].watermaxY;
            this.sectionOptions = formatSection(section.data);
          }
        }
      } catch (error) {
        console.error("error 获取断面数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取断面流速数据
    async getSectionFlowOptions(parmas) {
      try {
        this.sectionFlowOptions = formatSectionFlow(
          this.sectionPoint,
          parmas.max,
          parmas.min
        );
      } catch (error) {
        console.error("error 获取断面数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取流速图数据
    async getVelocityFlowOptions(isOnline, data) {
      try {
        if (isOnline) {
          this.velocityFlowOptions = formatVelocityFlow(data);
        } else {
          this.velocityFlowOptions = formatVelocityFlow(velocityFlow.data);
        }
      } catch (error) {
        console.error("error 获取流速图数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取水位流量图数据
    async getWaterLevelRateOptions(isOnline, parmas) {
      try {
        if (isOnline) {
          const res = await getApiWaterLevelRate(parmas);
          if (res.code == 200) {
            this.waterLevelRateOptions = cloneDeep(res.data);
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          this.waterLevelRateOptions = {};
        }
      } catch (error) {
        console.error("error 获取水位流量图数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取多站水位流量
    async getMultiStationWaterLevelRate(isOnline, parmas) {
      try {
        if (isOnline) {
          const res = await getApiMultiStationWaterLevelRate(parmas);
          if (res.code == 200) {
            this.multiStationWaterLevelRateOptions =
              formatMultiStationWaterLevelRate(cloneDeep(res.data));
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          this.multiStationWaterLevelRateOptions =
            formatMultiStationWaterLevelRate(multiStationWaterLevelRate.data);
        }
      } catch (error) {
        console.error("error 获取多站水位流量", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取水情预报
    async getWaterReport(isOnline, parmas) {
      try {
        if (isOnline) {
          const res = await getApiWaterReport(parmas);
          if (res.code == 200) {
            this.waterReportOptions = formatWaterReport(cloneDeep(res.data));
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          this.waterReportOptions = formatWaterReport(waterReport.data);
        }
      } catch (error) {
        console.error("error 获取水情预报", error.message);
        ElMessage.error(error.message);
      }
    },
    // 获取实时监控数据
    async getRealTimeMonitoring(isOnline, parmas) {
      try {
        if (isOnline) {
          const res = await getApiRealTimeMonitoring(parmas);
          if (res.code == 200) {
            this.realTimeMonitoringOptions = cloneDeep(res.data);
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          this.realTimeMonitoringOptions = {};
        }
      } catch (error) {
        console.error("error 获取实时监控数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 修改当前水位值
    setWaterterValue(val) {
      this.waterSlider.watervalue = val;
    },
    // 设置走航离线展示数据
    async setOfflineDataZouHang(parmas) {
      try {
        const res = await getApiDataSectionalFlow(parmas);
        if (res.code == 200) {
          this.offlineDataZouHang = res.data.flowData;
          this.cloudData = res.data.cloudData;
        } else {
          ElMessage.error(res.msg);
        }
      } catch (error) {
        console.error("error 设置走航离线展示数据", error.message);
        ElMessage.error(error.message);
      }
    },
    // 设置走航离线实时展示数据
    setNowDataZouhang(offlineZouHang, index) {
      if (offlineZouHang.length > 0) {
        this.nowDataZouhang.currentTraffic =
          offlineZouHang[index].currentTraffic;
        this.nowDataZouhang.sectionalArea = offlineZouHang[index].sectionalArea;
        this.nowDataZouhang.averageVelocity =
          offlineZouHang[index].averageVelocity;
      } else {
        this.nowDataZouhang.currentTraffic = 0;
        this.nowDataZouhang.sectionalArea = 0;
        this.nowDataZouhang.averageVelocity = 0;
      }
    },
    // 设置代表线表格数据
    setDetegateLineTableData(data, isOnline) {
      if (isOnline) {
        this.delegateTableData = data.Reports;
        this.delegateTableColumns = data.table
          .filter((item) => item.label !== "序号") // Filter out the item with label "序号"
          .map((item) => ({
            ...item,
            sortable: item.icon ? "custom" : false,
            width: item.label == "起点距" ? 60 : "",
          }));

        this.delegateTableTotal = data.total;
      } else {
        this.delegateTableData = lineAnalysis.Reports;
        this.delegateTableColumns = [
          { prop: "Distance", label: "Distance", width: "70" },
          { prop: "No", label: "No", width: "50" },
          { prop: "R", label: "R", sortable: true },
          { prop: "RU", label: "RU", sortable: true },
          { prop: "SE", label: "SE", sortable: true },
        ];
      }
    },
    // 代表线表格数据排序
    async detegateLineDataSort() {
      const res = await getRLineAnalysisOrder({
        ...this.page,
        ...this.orderBy,
      });
      if (res.code == 200) {
        this.setDetegateLineTableData(res.data, true);
        this.getSectionOptions(
          true,
          {
            xscd: "LFA03002632",
            msno: "2023-0",
          },
          0,
          this.direction == "vertical" ? 1 : 0
        );
      }
    },

    // 设置系统误差图表数据
    setErrOptions() {
      this.errorOptions = formatErrorOption(
        cloneDeep(this.delegateTableData),
        this.sectionXData
      );
    },
  },
});

export default echartsData;
