/*
 * @Description:
 * @Version:
 * @Author: Do not edit
 * @Date: 2024-05-28 14:54:31
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-06-26 10:07:59
 */

import CryptoJS from "crypto-js";
// 获取去年的年份
export function getLastYear() {
  const currentDate = new Date();
  const lastYear = currentDate.getFullYear() - 1;
  return lastYear;
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    } else if (typeof time === "string") {
      time = time
        .replace(new RegExp(/-/gm), "/")
        .replace("T", " ")
        .replace(new RegExp(/\.[\d]{3}/gm), "");
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

// 字符串截取
export function truncateDecimals(num, digits) {
  let numStr = num.toString();
  let decimalIndex = numStr.indexOf(".");

  if (decimalIndex === -1) {
    return numStr;
  }

  return Number(numStr.slice(0, decimalIndex + 1 + digits));
}

// 下载导出
export function downloadUrl(data, headers, request) {
  let disp = request.getResponseHeader("Content-Disposition");
  let fileName;
  let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  let matches = filenameRegex.exec(disp);
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, "");
  }
  // 通过 URLEncoder.encode(pFileName, StandardCharsets.UTF_8.name()) 加密编码的, 使用decodeURI(fileName) 解密
  fileName = decodeURI(fileName);
  console.log(fileName);
  let blob = new Blob([data], { type: "application/vnd.ms-excel" });
  let objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
  let downFile = document.createElement("a");
  downFile.style.display = "none";
  downFile.href = objectUrl;
  downFile.download = fileName; // 下载后文件名
  document.body.appendChild(downFile);
  downFile.click();
  document.body.removeChild(downFile); // 下载完成移除元素
  window.URL.revokeObjectURL(objectUrl);
}

// 导出form表单post
export function formExport(params, url) {
  var form = document.createElement("form");
  form.style.display = "none";
  form.action = url;
  form.method = "post";
  form.target = "";
  document.body.appendChild(form);
  // 动态创建input并给value赋值
  for (var key in params) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }
  form.submit();
  form.remove();
}
// 导出form表单get
export function formExportGet(params, url) {
  var form = document.createElement("form");
  form.style.display = "none";
  form.action = url;
  form.method = "get";
  form.target = "";
  document.body.appendChild(form);
  // 动态创建input并给value赋值
  for (var key in params) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }
  form.submit();
  form.remove();
}

export function decryptData(encryptedData) {
  const secretKey = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_ENCRYPTION_KEY);
  const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const decryptedBytes = CryptoJS.AES.decrypt(
    { ciphertext: encryptedBytes },
    secretKey,
    { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
  );
  const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);

  return decryptedData;
}

export function formdataHigh(x0, y0, rows, cols, HZOOM, split) {
  // String manipulation to form `str`
  let str = (x0 + y0).replace(/\./g, "").replace(/0/g, "");
  let strs = str.split("");

  // Calculating average
  let sum = strs.map(Number).reduce((a, b) => a + b, 0);
  let t = sum / strs.length;
  t = Math.floor(t); // Convert average to an integer

  // Adjusting `t` based on conditions
  if (cols === 2) {
    t = 1;
  } else if (t <= 1 || t >= cols) {
    t = 2;
  }

  // Calculating dimensions
  let nums = rows * cols;
  let rows2 = rows * t;
  let cols2 = Math.ceil(cols / t);
  let k = nums - Math.floor(nums / rows2) * rows2;
  let index = 0;
  let arrayListNew = [];

  for (let i = 0; i < cols2; i++) {
    for (let j = 0; j < rows2; j++) {
      let num =
        (nums % rows2 === 0 || j < k
          ? j * cols2
          : k * cols2 + (j - k) * (cols2 - 1)) + i;

      if (j >= k && i === cols2 - 1 && nums % rows2 !== 0) {
        continue;
      }

      let d = parseFloat(split[num]);
      if (d !== -99999.0) {
        // Decrypt
        // d /= HZOOM;
        // Encrypt: (current value - min value) / (max value - min value), rounded to one decimal
        d = parseFloat((d / parseFloat(strs[index])).toFixed(1));

        if (index >= strs.length - 1) {
          index = 0;
        } else {
          index++;
        }
      }
      arrayListNew.push(d);
    }
  }

  return arrayListNew;
}

/**
 * 根据两个点插值出坐标点数组
 * @param {*} lat1 点1x
 * @param {*} lon1 点1y
 * @param {*} lat2 点2x
 * @param {*} lon2 点2y
 * @param {*} numPoints 插值个数
 * @returns
 */
export function interpolateCoordinates(lat1, lon1, lat2, lon2, numPoints) {
  var coordinates = [];
  for (var i = 0; i <= numPoints; i++) {
    var fraction = i / numPoints;
    var lat = (1 - fraction) * lat1 + fraction * lat2;
    var lon = (1 - fraction) * lon1 + fraction * lon2;
    coordinates.push([Number(lat.toFixed(6)), Number(lon.toFixed(6))]);
  }
  return coordinates;
}

/**
 * 根据个数进行分割数组
 * @param {*} arr 需要分割的数组
 * @param {*} chunkSize 分割的位数
 * @returns
 */
export function splitArray(arr, chunkSize) {
  var result = [];
  for (var i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

/**
 * 计算两个颜色之间的插值
 * @param {*} color1 颜色1
 * @param {*} color2 颜色2
 * @param {*} t 插值系数
 * @returns
 */
export function lerpColor(color1, color2, t) {
  var r = Number(((color1.r + t * (color2.r - color1.r)) / 255).toFixed(2));
  var g = Number(((color1.g + t * (color2.g - color1.g)) / 255).toFixed(2));
  var b = Number(((color1.b + t * (color2.b - color1.b)) / 255).toFixed(2));
  return [r, g, b, 1];
}

/**
 * 寻找输入值对应数组的最近的索引
 * @param {*} arr 判断的数组
 * @param {*} value 输入的值
 * @returns 对应最接近的索引
 */
export function findClosestIndex(arr, value) {
  let minDiff = Infinity;
  let closestIndex = -1;

  arr.forEach((num, index) => {
    const diff = Math.abs(num - value);
    if (diff < minDiff) {
      minDiff = diff;
      closestIndex = index;
    }
  });

  return closestIndex;
}
