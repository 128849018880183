import { defineStore } from "pinia";

const coverRightbox = defineStore("coverRightbox", {
  state: () => ({
    homeViewStr: "home",
    isShowZouHang: 'offline'
  }),
  actions: {
    // 设置底部展示布局home,section
    setHomeViewStr(str) {
      this.homeViewStr = str;
    },
    // 重置数据
    resetHomeViewStr() {
      this.homeViewStr = 'home'
    },
    // 设置走航展示模式 online在线 offline离线
    setShowZouHang(value) {
      this.isShowZouHang = value
    },
    // 重置走航展示模式 online在线 offline离线
    resetShowZouHang() {
      this.isShowZouHang = 'offline'
    },
  }
})
export default coverRightbox;