
import { defineStore } from "pinia";
import { getModelInfoList } from '@/api/gaeaModule.js'
import { ElMessage } from 'element-plus'

const gaeaModule = defineStore("gaeaModule", {
  state: () => ({
    gaeaModuleList: [], // 模型类型信息
    gaeaModuleListType: [], // 模型类型
    gaeaModuleListGltf: [],// 模型gltf
    gaeaModuleListLabel: [],// 模型label标记
  }),
  actions: {
    // 获取模型类型
    async setGaeaModuleListType(params) {
      try {
        const res = await getModelInfoList(params)
        if (res.code == 200) {
          this.gaeaModuleListType = res.data
        } else {
          ElMessage.error(res.msg)
        }

      } catch (error) {
        console.log('error 获取模型类型', error.message)
        ElMessage.error(error.message)
      }
    },
    // 获取对应类型下的模型gltf
    async setGaeaModuleListGltf() {
      try {
        const res = await getModelInfoList({
          type: 'gltf'
        })
        if (res.code == 200) {
          this.gaeaModuleListGltf = res.data
        } else {
          ElMessage.error(res.msg)
        }
      } catch (error) {
        console.log('error 获取对应类型下的模型gltf', error.message)
        ElMessage.error(error.message)
      }
    },
    // 获取对应类型下的标记label
    async setGaeaModuleListLabel() {
      try {
        const res = await getModelInfoList({
          type: 'label'
        })
        if (res.code == 200) {
          this.gaeaModuleListGltf = res.data
        } else {
          ElMessage.error(res.msg)
        }

      } catch (error) {
        console.log('error 获取对应类型下的标记label', error.message)
        ElMessage.error(error.message)
      }
    }
  }
});

export default gaeaModule;
