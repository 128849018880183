import { defineStore } from "pinia";
const user = defineStore("user", {
  state: () => ({
  }),
  actions: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("shashiToken");
    },
  },
});

export default user;
