import { defineStore } from "pinia";

const store = defineStore("elements", {
  state: () => ({
    resData: {}, // 全要素数据查询结果
    equipmentObj: {}, // 当前选择设备
  }),
  actions: {
    setResData(data) {
      this.resData = data;
    },
    setEquipment(data) {
      this.equipmentObj = data;
    },
  },
});
export default store;
