/*
 * @Description: 
 * @Version: 
 * @Author: Do not edit
 * @Date: 2024-07-11 09:49:27
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-07-12 15:44:17
 */
import { get, post } from "./axios";

// 水沙分析获取地形项目
export const getPrjsApi = (p) => post("/swns/dem/prjs", p, true);

// 水沙分析获取测次
export const getMsnoApi = (p) => post("/swns/dem/msnos", p, true);

// 水沙分析获取坐标
export const getBoundaryApi = (p) => post("/swns/dem/boundary", p, true);
// 水沙分析获取坐标
export const getBoundarysApi = (p) => post("/swns/dem/boundarys", p, true);

// 开始计算冲淤厚度图
export const getGetCyApi = (p) => post("/swns/dem/getCy", p, true);

// 计算多边形冲淤
export const getEvaluateApi = (p) => post("/swns/dem/evaluate", p, true);

// 多选项目获取测次
export const getSelectMsnosApi = (p) =>
  post("/swns/dem/selectMsnos", p, true);

// 开始计算槽蓄量高程关系
export const getCapacityApi = (p) =>
  post("/swns/dem/getCapacity", p, true);

// 任意断面切割-绘制断面
export const getDemForMsnoApi = (p) =>
  post("/swns/sect/xsinfo/getDemForMsno", p, true);

// 河床坡度—生成底图
export const getBedrockApi = (p) => post("/swns/dem/getDemToGrode", p, true);


// 获取获取dem生成局部地形json
export const getDemJson = (p) => post("/swns/png/getDemToJson", p, true);

// 水沙分析获取坐标Hd
export const getBoundaryHdApi = (p) => post("/swns/dem/boundaryHd", p, true);

// 获取流量数据
export const obtainTrafficData = (p) => get("/datas/adcp/getFlowInfo", p, true);

// 获取断面测点数据转坐标数据
export const selectMsxsrsEnd = (p) => post("/swns/sect/xsinfo/selectMsxsrsEnd", p, true);

// 获取断面
export const getAllBdmStcdApi = (p) => post("/swns/stsc/allBdmStcd", p, true);

// 根据断面获取测次
export const getBigSectTimeApi = (p) => post("/swns/stsc/getBigSectTime", p, true);

// 根据断面-测次绘制图表
export const getChartApi = (p) => post("/swns/sect/xsmsrs/chart", p, true);

// 获取六要素大断面
export const getSixApi = (p) => post("/swns/sect/xsmsrs/getSixs", p, true);
// 获取六要素固定断面单个
export const getSixOneApiMs = (p) => post("/swns/sect/msxsrs/getSix", p, true);
// 获取六要素固定断面多个
export const getSixApiMs = (p) => post("/swns/sect/msxsrs/getSixs", p, true);

// 获取深泓线
export const getDemToIdentifyShLineApi = (p) => post("/swns/png/getDemToIdentifyShLine", p, true);

// 获取分区
export const getAreaApi = (p) => post('/swns/base/basin/area', p, true)
// 获取河流
export const getModApi = (p) => post('/swns/base/river/mod', p, true)

// 获取断面
export const getSectionApi = (p) => post('/swns/base/section/section', p, true)

// 获取测次
export const getQueryDmxCcAndKljpApi = (p) => post('/swns/sect/xshd/queryDmxCcAndKljp', p, true)

// 获取固端分析
export const getQueryDmxChartApi = (p) => post('/swns/sect/msxsrs/queryDmxChart', p, true)
