/*
 * @Description:
 * @Version:
 * @Author: Do not edit
 * @Date: 2024-07-22 09:12:48
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-07-30 09:48:31
 */

import { defineStore } from "pinia";

const coverleftbox = defineStore("coverleftbox", {
  state: () => ({
    showThreeDemo: false, // 是否显示three.js demo
    configThree: {
      show: [2, 3],
    }, // three.js 数据配置
    zoom2: 10,
    // 进度条分类
    sliderType: {
      typeName: "",
      marks: {},
      sliderConfig: {
        max: 0,
        min: 0,
        step: 0,
        value: 0,
        time: 0,
      },
    },
    // 进度条当前值
    sliderCurrentValue: {
      flag: true,
      value: 0,
      isContinue: false,
    },
    // 色卡数组
    colorCardValue: [],
    // 色卡名称
    colorCardName: "",
    // threeDemo 数据
    threeData: [],
    // leftbox 宽高
    leftbox: {
      width: 0,
      height: 0,
    },
  }),
  actions: {
    setConfigThree(config) {
      console.log(`output->configThree`, config);
      this.configThree.show = config.show;
      this.zoom2 = config.zoom2;
    },
    // 展示three.js demo
    handleShowDemo(value) {
      this.showThreeDemo = value;
    },
  },
});

export default coverleftbox;
