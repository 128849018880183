/*
 * @Description: 
 * @Version: 
 * @Author: Do not edit
 * @Date: 2024-06-11 09:20:45
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-07-22 09:13:01
 */
// import { defineStore } from 'pinia'
import common from "./module/common"; //系统相关
import user from "./module/user"; //用户相关
import gaeaData from "./module/gaeaData"; //gaea球相关
import useObservationStore from "./module/observation"; //水沙分析
import coverleftbox from "./module/coverleftbox"; //左侧展示区域
import coverRightbox from './module/coverRightbox';//右侧展示区域
import echartsData from "./module/echartsData"; //全局echats数据管理
import elements from "./module/allElements";
import gaeaModule from './module/gaeaModule';

export default function Store() {
  return {
    common: common(),
    user: user(),
    gaeaData: gaeaData(),
    useObservationStore: useObservationStore(),
    coverleftbox: coverleftbox(),
    coverRightbox: coverRightbox(),
    echartsData: echartsData(),
    elements: elements(),
    gaeaModule: gaeaModule()
  };
}
