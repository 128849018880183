import { defineStore } from "pinia";
import { ElMessage } from "element-plus";
import * as Cesium from "cesium/Cesium";
import { getPrjsApi } from "@/api/waterSedimentAnalysis";
const useObservationStore = defineStore({
  id: "observationStore",
  state: () => ({
    viewer: null,
    projectOptions: [], // 地形选择项目
    point: "",
    cameraHeight: null, // 相机高度
    pointObj: {},// 每次画点的坐标
    onePointPositison: [],// 当前点击点的
    isOnePonit: false,
    dataSourcePoint: []
  }),
  actions: {
    async getProjectOptions() {
      try {
        const res = await getPrjsApi();
        if (res) {
          this.projectOptions = res;
        }
      } catch (error) {
        ElMessage.error(error.message);
      }
    },
    async setdrawPointObj(val) {
      try {
        console.log(`output->setdrawPointObj`, val)
        this.pointObj = val
      } catch (error) {
        ElMessage.error(error.message);
      }
    },
    async setOnePointPositison(val) {
      // debugger
      console.log(`output->val`, val)
      try {
        // this.onePointPositison.push(val)
      } catch (error) {
        ElMessage.error(error.message);
      }
    },
    async clearOnePointPositison(id) {
      try {
        let index = this.dataSourcePoint.findIndex((item) => item.id == id)
        if (index > -1) {
          this.dataSourcePoint.splice(index, 1)
          console.log(`output->this.dataSourcePoint`, this.dataSourcePoint)
          this.isOnePonit = false
        }
      } catch (error) {
        ElMessage.error(error.message);
      }
    },
    async setDataSource(obj) {

      let index = this.dataSourcePoint.findIndex((item) => item.id == obj.id)
      if (index > -1) {
        this.isOnePonit = true
      } else {
        this.dataSourcePoint.push(obj)
        console.log(`output-> this.dataSourcePoint`, this.dataSourcePoint)
        this.isOnePonit = false
      }

    },
    async initIsOnePonit(id) {
      if (id) {
        let index = this.dataSourcePoint.findIndex((item) => item.id == id)
        if (index > -1) {
          // this.isOnePonit = true
        } else {
          this.isOnePonit = false
        }
      } else {
        this.isOnePonit = false
      }

    },

  },
});
export default useObservationStore;
