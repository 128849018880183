import axios from "axios";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import store from "@/store"

const $router = useRouter();
const service = axios.create({
  // 设置默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  // 请求超时时间
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 获取用户信息即token
    const token = localStorage.getItem("token");
    if (token && !config.headers.hasOwnProperty("Authorization")) {
      config.headers["Authorization"] = token; // 让每个请求都带token
    }
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    if (response?.data?.code && response.data.code == 444) {
      store().user.logout();
      $router.push('/login');
      return false
    }
    return response;
  },
  (error) => {
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "未授权，请重新登录";
          store().user.logout();
          $router.push({ name: "login" });
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        case 501:
          error.message = "网络未实现";
          break;
        case 502:
          error.message = "网络错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网络超时";
          break;
        case 505:
          error.message = "http版本不支持该请求";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
      ElMessage.error(error.message);
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        ElMessage.error("服务器响应超时，请刷新当前页");
      }
      error.message = "连接服务器失败";

    }
    return Promise.resolve(error.response);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params, type) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params,
        headers: {
          "Content-Type": "application/json",
          ...type,
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, isForm, type, isDownload) {
  return new Promise((resolve, reject) => {
    let newData = params;
    if (isForm) {
      //  转formData格式
      newData = new FormData();
      for (let i in params) {
        newData.append(i, params[i]);
      }
    }
    service
      .post(url, newData, {
        headers: {
          "Content-Type": isForm
            ? "application/x-www-form-urlencoded;charset=UTF-8"
            : "application/json",
          ...type,
        },
      })
      .then((res) => {
        if (isDownload) {
          resolve(res);
        } else {
          resolve(res.data);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    service
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * delete方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Delete(url, params) {
  return new Promise((resolve, reject) => {
    service
      .delete(url, { data: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
