/*
 * @Descripttion:
 * @version:
 * @Author: xcb
 * @Date: 2023-03-06 09:21:00
 * @LastEditors: yangyongtao
 * @LastEditTime: 2024-04-10 09:25:40
 */
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";

export const constantRoutes = [
  {
    path: "/",
    name: "/",
    component: () => import("@/views/home/home.vue"),
    meta: {
      page: "public",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  // 孪生GPT
  {
    path: "/riverWiki",
    name: "riverWiki",
    component: () => import("@/views/riverWiki/index.vue"),
  },
  {
    path: "/riverLancet",
    name: "riverLancet",
    component: () => import("@/views/riverLancet/index.vue"),
  },
  {
    path: "/waterAnalysis",
    name: "waterAnalysis",
    component: () => import("@/views/waterAnalysis/index.vue"),
  },
  // 全物联远控
  {
    path: "/intelligentControl",
    name: "intelligentControl",
    component: () => import("@/views/intelligentControl/index.vue"),
    children: [
      {
        path: "iframeContent",
        name: "iframeContent",
        component: () => import("@/views/intelligentControl/iframeContent"),
      },
      {
        path: "dataManagement",
        name: "dataManagement",
        component: () => import("@/views/intelligentControl/dataManagement"),
      },
    ],
  },
  // 全要素大屏
  {
    path: "/allElements",
    name: "allElements",
    component: () => import("@/views/allElements/index.vue"),
  },
  {
    path: '/gaea',
    name: "gaea",
    component: () => import("@/views/gaea/index.vue"),
  },
  // 全要素数据
  {
    path: "/elementsData",
    name: "elementsData",
    component: () => import("@/views/elementsData/index.vue"),
    children: [
      {
        path: "/elementsData/waterLevel",
        name: "waterLevel",
        component: () => import("@/views/elementsData/waterLevel/index.vue"),
        group: "elements",
      },
      {
        path: "/elementsData/areaRainfall",
        name: "areaRainfall",
        component: () => import("@/views/elementsData/areaRainfall/index.vue"),
        group: "elements",
      }
    ],
  },
  // 计算模型
  {
    path: "/computed",
    name: "computed",
    component: () => import("@/views/computed/index.vue"),
  },
  // 分析统计
  {
    path: "/analysis",
    name: "analysis",
    component: () => import("@/views/analysis/index.vue"),
    children: [
      {
        path: "/analysis/reports", // 单站月报
        name: "reports",
        component: () => import("@/views/analysis/reports/index.vue"),
        group: "analysis",
      },
      {
        path: "/analysis/reportsDay", // 多站日报
        name: "reportsDay",
        component: () => import("@/views/analysis/reportsDay/index.vue"),
        group: "analysis",
      },
      {
        path: "/analysis/reportsMonth", // 多站月报
        name: "reportsMonth",
        component: () => import("@/views/analysis/reportsMonth/index.vue"),
        group: "analysis",
      },
      {
        path: "/analysis/reportsOpen", // 畅通率报表
        name: "reportsOpen",
        component: () => import("@/views/analysis/reportsOpen/index.vue"),
        group: "analysis",
      },
      {
        path: "/analysis/reportsDaobao", // 到报率
        name: "reportsDaobao",
        component: () => import("@/views/analysis/reportsDaobao/index.vue"),
        group: "analysis",
      },
    ]
  },
  //数据分析
  {
    path: "/dataanalysis",
    name: "dataanalysis",
    component: () => import("@/views/dataanalysis/index.vue"),
    children:[
      {
        path: "/dataanalysis/historycontrast", //历史同期对比
        name: "historycontrast",
        component: () => import("@/views/dataanalysis/historycontrast/index.vue"),
        group: "dataanalysis",
      },
      {
        path: "/dataanalysis/monthlyvaluecontrast", //旬月值对比
        name: "monthlyvaluecontrast",
        component: () => import("@/views/dataanalysis/monthlyvaluecontrast/index.vue"),
        group: "dataanalysis",
      },
      {
        path: "/dataanalysis/historicontrast", //历史距平
        name: "historicontrast",
        component: () => import("@/views/dataanalysis/historicontrast/index.vue"),
        group: "dataanalysis",
      },
      {
        path: "/dataanalysis/floodcontrast", //洪水成果
        name: "floodcontrast",
        component: () => import("@/views/dataanalysis/floodcontrast/index.vue"),
        group: "dataanalysis",
      }
    ]
  },
  // 通讯配置
  {
    path: "/communication",
    name: "communication",
    component: () => import("@/views/communication/index.vue"),
  },
  // 系统设置
  {
    path: "/system",
    name: "system",
    component: () => import("@/views/system/index.vue"),
    children: [
      {
        path: "/system/menu",
        name: "menu",
        component: () => import("@/views/system/menu/index.vue"),
        group: "system",
      },
      {
        path: "/system/user",
        name: "user",
        component: () => import("@/views/system/user/index.vue"),
        group: "system",
      },
      {
        path: "/system/role",
        name: "role",
        component: () => import("@/views/system/role/index.vue"),
        group: "system",
      },
      {
        path: "/system/dictionary",
        name: "dictionary",
        component: () => import("@/views/system/dictionary/index.vue"),
        group: "system",
      },
      {
        path: "/system/dictionarydetails",
        name: "dictionarydetails",
        component: () => import("@/views/system/dictionarydetails/index.vue"),
        group: "system",
      },
      // 设备管理
      {
        path: "/system/equipment",
        name: "equipment",
        component: () => import("@/views/system/equipment/index.vue"),
        group: "system",
      },
      // 站网管理
      {
        path: "/system/netInfo",
        name: "netInfo",
        component: () => import("@/views/system/netInfo/index.vue"),
        group: "system",
      },
      // 权限管理
      {
        path: "/system/dataPermissions",
        name: "dataPermissions",
        component: () => import("@/views/system/dataPermissions/index.vue"),
        group: "system",
      },
      // 部门管理
      {
        path: "/system/Organ",
        name: "Organ",
        component: () => import("@/views/system/Organ/index.vue"),
        group: "system",
      },
      // 上级河流
      {
        path: "/system/Superiorriver",
        name: "Superiorriver",
        component: () => import("@/views/system/Superiorriver/index.vue"),
        group: "system",
      },
    ],
  },
  // 全流程管理
  {
    path: "/process",
    name: "process",
    component: () => import("@/views/process/index.vue"),
  },
  // 宣传材料
  {
    path: "/publicizeMaterials",
    name: "publicizeMaterials",
    component: () => import("@/views/publicizeMaterials/index.vue"),
    meta: {
      page: "public",
    },
  },
  // 宣传视频
  {
    path: "/publicizeVideo",
    name: "publicizeVideo",
    component: () => import("@/views/publicizeVideo/index.vue"),
    meta: {
      page: "public",
    },
  },
  // 材料下载
  {
    path: "/downloadMaterials",
    name: "downloadMaterials",
    component: () => import("@/views/downloadMaterials/index.vue"),
    meta: {
      page: "download",
    },
  },
  // 长新联盟
  {
    path: "/union",
    name: "union",
    component: () => import("@/views/union/index.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

router.beforeEach((to, from, next) => {
  // 登陆状态判断
  if (to.path === "/login" || to?.meta?.page === "public") {
    next();
  } else {
    const token = localStorage.getItem("token");
    if (token) {
      next();
    } else {
      ElMessage.error("请先登录");
      next("/login");
    }
  }
  next()
});

export default router;
