import { post } from "./axios";

// 获取断面折线图
export const getApiSection = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取水位流量
export const getApiWaterLevelRate = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取流速图
export const getApiVelocityFlow = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取多站水位流量
export const getApiMultiStationWaterLevelRate = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取水情预报
export const getApiWaterReport = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取实时监控数据
export const getApiRealTimeMonitoring = (p) =>
  post("/swns/sect/msxsrs/queryDmxChart", p, true);

// 获取走航离线展示数据
export const getApiDataSectionalFlow = (p) =>
  post("/datas/adcpFlow/getDataSectionalFlow", p, true);

// 获取代表线分析表格数据
export const getRLineAnalysis = (p) =>
  post("/datas/adcp/rLineAnalysis", p, true);

// 代表线表格数据排序
export const getRLineAnalysisOrder = (p) =>
  post("/datas/adcp/rLineAnalysisSort", p, true);

// 获取代表线断面数据
export const getRLineAnalysisSection = (p) =>
  post("/datas/adcp/getRlineXsnm", p, true);

// 获取代表线项目数据
export const getRLineAnalysisPronm = (p) =>
  post("/datas/adcp/getRlinePronm", p, true);

// 获取代表线测次数据
export const getRLineAnalysisMsno = (p) =>
  post("/datas/adcp/getRlineMsno", p, true);
