import { parseTime } from "@/utils/tools";
import { getColorForValue } from "@/utils/vectorSynthesisFun";
// 水情
export const formatWaterReport = function (data) {
  return {
    grid: {
      left: 10,
      right: 10,
      bottom: 10,
      top: 60,
      containLabel: true,
    },
    color: ["rgba(0, 242, 234, 1)", "#e9546b", "#4080FF", "#FF00EE"],
    title: {
      text: "",
      left: "center",
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
      textStyle: {
        color: "#fff",
      },
      data: ["水位", "流量", "水位预报", "流量预报"],
    },
    textStyle: {
      fontSize: "16px",
    },
    xAxis: {
      name: "",
      type: "category",
      scale: true,
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
      boundaryGap: true,
      axisLabel: {
        color: "#fff",
        fontSize: "16px",
        formatter: parseTime(new Date(), "{y}-{m}-{d}"),
      },
    },
    yAxis: [
      {
        type: "value",
        name: "水位(m)",
        nameGap: 20,
        nameTextStyle: {
          color: "#fff",
        },
        scale: true,
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            width: 1,
            color: "#fff",
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            width: 1,
            color: "#ccc",
            opacity: 0.3,
          },
        },
        axisLabel: {
          color: "#fff",
          fontSize: "16px",
          formatter: function (value, index) {
            return value.toFixed(2);
          },
        },
      },
      {
        type: "value",
        name: "流量(m³/s)",
        nameGap: 20,
        nameTextStyle: {
          color: "#fff",
        },
        scale: true,
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            width: 1,
            color: "#fff",
          },
        },
        splitLine: {
          show: false,
        },
        // boundaryGap: ["20%", "10%"],
        axisLabel: {
          color: "#fff",
          fontSize: "16px",
        },
      },
    ],
    series: [
      {
        name: "水位",
        type: "line",
        data: data.real.map((item) => [item.tm, item.z]),
        yAxisIndex: 0,
        symbol: "none",
        lineStyle: {
          width: 2,
        },
        // areaStyle: {}
      },
      {
        name: "流量",
        type: "line",
        data: data.real.map((item) => [item.tm, item.q]),
        yAxisIndex: 1,
        symbol: "none",
        lineStyle: {
          width: 2,
        },
        // areaStyle: {}
      },
      {
        name: "水位预报",
        type: "line",
        data: data.yb?.map((item) => [item.ymdh, item.z]),
        symbol: "none",
        lineStyle: {
          type: "dashed",
          width: 2,
        },
      },
      {
        name: "流量预报",
        type: "line",
        data: data.yb?.map((item) => [item.ymdh, item.q]),
        symbol: "none",
        lineStyle: {
          type: "dashed",
          width: 2,
        },
      },
    ],
  };
};
// 多站
export const formatMultiStationWaterLevelRate = function (data) {
  return data.list;
};
// 流速图
export const formatVelocityFlow = function (data) {
  const xData = [];
  const yData = [];
  data.forEach((item) => {
    xData.unshift(item.vel);
    yData.unshift(item.high);
  });
  return {
    grid: {
      left: 10,
      right: 68,
      bottom: 10,
      top: 60,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      orient: "horizontal",
      type: "scroll",
      width: "80%",
      left: "center",
      y: 20,
      data: [],
      textStyle: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    xAxis: {
      name: "流速(m/s)",
      type: "value",
      boundaryGap: [0, 0.01],
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
    },
    yAxis: {
      name: "水深(m)",
      type: "category",
      data: yData,
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
    },
    series: [
      {
        type: "bar",
        data: xData,
      },
    ],
  };
};

const redColors = [
  "#FFC0CB", // 浅粉色
  "#FF69B4", // 粉红色
  "#FF1493", // 热粉色
  "#FF7F50", // 珊瑚色
  "#FF4500", // 橙红色
  "#FF0000", // 红色
  "#DC143C", // 深红色
  "#8B0000", // 暗红色
  "#800000", // 酒红色
  "#6A1B2A", // 褐红色
];

// 断面图
export const formatSection = function (
  data,
  delegateLineData,
  no,
  model
) {
  let xData = data.series[0].data.map((item) => {
    return item[0];
  });
  const option = {
    backgroundColor: "transparent",
    color: [
      "#2ec7c9",
      "#b6a2de",
      "#5ab1ef",
      "#ffb980",
      "#d87a80",
      "#8d98b3",
      "#e5cf0d",
      "#97b552",
    ],
    title: {
      text: "断面:沙市(二郎矶)",
      left: "center",
      textStyle: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    legend: {
      orient: "horizontal",
      type: "scroll",
      width: "80%",
      left: "center",
      y: 20,
      data: [],
      textStyle: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    grid: {
      left: 30,
      right: 70,
      bottom: 10,
      top: 30,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      hideDelay: 2000,
    },
    xAxis: {
      name: "起点距(m)",
      nameGap: 5,
      nameTextStyle: {
        fontSize: "12px",
      },
      type: data.series.length === 1 ? "value" : "value",
      axisLine: {
        onZero: false,
        lineStyle: {
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: true,
        inside: true,
      },
      axisLabel: {
        fontSize: "12px",
      },
      min: Math.min(...xData),
      max: Math.max(...xData),
      boundaryGap: false,
      data: xData,
    },
    yAxis: [
      {
        name: "高程(m)",
        nameTextStyle: {
          fontSize: "12px",
        },
        scale: true,
        splitLine: {
          show: false,
          lineStyle: {
            type: "solid",
            width: 1,
          },
        },
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            color: "#fff",
          },
        },
        axisTick: {
          show: true,
          inside: true,
        },
        axisLabel: {
          fontSize: "12px",
        },
      },
    ],
    series: [],
  };

  data.series.forEach((item, index) => {
    let sectionSeries = {};
    sectionSeries = {
      data: item.data,
      name: item.name,
      type: item.type,
      symbol: "circle",
      symbolSize: 5,
      showSymbol: false,
      z: 2,
      lineStyle: {
        normal: {
          color: "rgb(150,120,80)",
          width: 2,
          type: index == 0 ? "solid" : "dashed",
        },
      },
    };
    if (data.series.length === 1) {
      sectionSeries = {
        ...sectionSeries,
        areaStyle: {
          normal: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgb(150,120,80)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgb(150,120,80)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
            origin: "start",
            opacity: 1,
          },
        },
      };
    }
    option.series.push(sectionSeries);
    option.legend.data.push({
      name: item.name,
      type: item.type,
    });
  });

  let series = {};
  if (delegateLineData && delegateLineData.length) {
    let delegateLineArr = [];
    if (no) {
      delegateLineArr = delegateLineData.filter((item) => item.no == no);
    } else {
      delegateLineArr = delegateLineData.slice(0, 1);
    }
    let data = [];
    if (model == 0) {
      data = [
        [0, delegateLineArr[0].distance],
        [1000, delegateLineArr[0].distance],
      ];
    } else {
      data = [
        [delegateLineArr[0].distance, 0],
        [delegateLineArr[0].distance, 45],
      ];
    }
    series = {
      name: `代表线`,
      type: "line",
      symbol: "none",
      lineStyle: {
        type: "solid",
        color: "#F00",
        width: 2,
      },
      data: data,
      z: 0,
    };
    option.legend.data.push({
      name: "代表线",
    });
    option.series.push(series);
  }

  return option;
};

// 断面流速图
export const formatSectionFlow = function (data, flowMax, flowMin) {
  let option = {
    grid: {
      left: 10,
      right: 68,
      bottom: 10,
      top: 60,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      name: "x",
      type: "value",
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      name: "y",
      type: "value",
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: "scatter",
        data: data.map(function (item) {
          return {
            value: [item.x, item.y],
            symbolSize: 4, // 设置散点大小
            itemStyle: {
              color: "#fff", // 散点颜色
            },
          };
        }),
        markLine: {
          symbol: ["circle", "arrow"], //箭头
          data: data.map((item, i) => {
            return [
              {
                name: "",
                coord: [String(item.x), String(item.y)],
                lineStyle: {
                  type: "dashed",
                  color: getColorForValue(flowMin, flowMax, item.v),
                },
              },
              {
                coord: [String(item.x2), String(item.y2)],
              },
            ];
          }),
        },
      },
    ],
  };

  // 根据 data 数组动态更新 x 轴的最大和最小值
  option.xAxis.min = Math.min(...data.map((item) => item.x)) - 20;
  option.xAxis.max = Math.max(...data.map((item) => item.x)) + 20;

  // 根据 data 数组动态更新 y 轴的最大和最小值
  option.yAxis.min = Math.min(...data.map((item) => item.y)) - 300;
  option.yAxis.max = Math.max(...data.map((item) => item.y)) + 20;

  return option;
};

// 系统误差图
export const formatErrorOption = function (data, xdata) {
  data = data.sort((a, b) => {
    return a.no - b.no;
  });
  let option = {
    legend: {
      orient: "horizontal",
      type: "scroll",
      width: "80%",
      left: "center",
      top: 0,
      data: ["系统误差", "随机不确定度"],
      textStyle: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    grid: {
      left: 20,
      right: 40,
      bottom: 0,
      top: 15,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      name: "起点距(m)",
      nameGap: 5,
      nameTextStyle: {
        fontSize: "12px",
      },
      type: "value",
      axisLine: {
        show: true,
        onZero: false,
        lineStyle: {
          width: 1,
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },

      min: xdata[0],
      max: xdata[xdata.length - 1],
      data: xdata,
    },
    yAxis: [
      {
        name: "系统误差",
        nameGap: 0,
        type: "value",
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            width: 1,
            color: "#fff",
          },
        },
        splitLine: {
          show: false,
        },
      },
      {
        name: "随机不确定度",
        nameGap: 0,
        type: "value",
        axisLine: {
          show: true,
          onZero: false,
          lineStyle: {
            width: 1,
            color: "#fff",
          },
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: `系统误差`,
        type: "line",
        symbol: "none",
        lineStyle: {
          type: "solid",
          width: 2,
        },
        data: data.map((item) => {
          return [item.distance, item.se];
        }),
        z: 0,
      },
      {
        name: `随机不确定度`,
        type: "line",
        symbol: "none",
        lineStyle: {
          type: "solid",
          width: 2,
        },
        data: data.map((item) => {
          return [item.distance, item.ru];
        }),
        z: 0,
        yAxisIndex: 1,
      },
    ],
  };

  return option;
};
