import { defineStore } from 'pinia'
const common = defineStore('common', {
    state: () => (
        {
            positionList: [
                {
                    name: "荆州",
                    localtion: {
                        x: 30.31473,
                        y: 112.23128,
                        z: 10000
                    }
                }
            ]
        }
    ),
    // ...
})

export default common