/**
 * 多个流速流向合成一个
 * @param {*} data 单条垂线流速流向数组
 * @returns
 */
const vectorSynthesisFun = (data) => {
  // 将方位角转换为弧度
  data.forEach(function (item) {
    item.dir_rad = (item.dir * Math.PI) / 180;
  });

  // 计算每个流速的x和y分量
  data.forEach(function (item) {
    item.vx = item.velocity * Math.cos(item.dir_rad);
    item.vy = item.velocity * Math.sin(item.dir_rad);
  });

  // 计算平均x和y分量
  var avg_vx = data.reduce((sum, item) => sum + item.vx, 0) / data.length;
  var avg_vy = data.reduce((sum, item) => sum + item.vy, 0) / data.length;

  // 计算合成的平均流速的大小和方向
  var avg_velocity = Math.sqrt(avg_vx * avg_vx + avg_vy * avg_vy);
  var avg_dir_rad = Math.atan2(avg_vy, avg_vx);
  var avg_dir_deg = (avg_dir_rad * 180) / Math.PI;

  return {
    v: avg_velocity,
    deg: avg_dir_deg,
  };
};

/**
 * 坐标点角度流速生成新的坐标点
 * @param {*} x1 平面坐标的x
 * @param {*} y1 平面坐标的y
 * @param {*} distance 流速
 * @param {*} angle 角度
 * @param {*} max 目前流速最大值
 * @param {*} min 目前流速最小值
 * @param {*} dy y轴最大最小插值
 * @param {*} dx x轴最大最小插值
 * @returns
 */
const calculateNewCoordinates = (x1, y1, distance, angle, max, min, dy, dx) => {
  let ratio = (distance - min) / (max - min);
  let num = dy > dx ? dy : dx;
  distance = distance * ratio * num;
  
  angle = angle - 180;
  // 将角度转换为弧度
  var angleRad = (angle * Math.PI) / 180;

  // 计算新点的坐标
  var x2 = x1 + distance * Math.cos(angleRad);
  var y2 = y1 + distance * Math.sin(angleRad);
  
  return { x2: x2, y2: y2 };
};

/**
 *
 * @param {*} minValue 最小值
 * @param {*} maxValue 最大值
 * @param {*} currentValue 当前值
 * @param {*} colorArray 颜色数组
 * @returns 返回对应的颜色
 */
const getColorForValue = (
  minValue,
  maxValue,
  currentValue,
  colorArray = [
    "#ff0000",
    "#ff7000",
    "#ffe300",
    "#abff00",
    "#38ff00",
    "#00ff38",
    "#00ffab",
    "#00e3ff",
    "#0070ff",
    "#0000ff",
  ]
) => {
  colorArray = colorArray.reverse();
  // 计算当前值在最小值和最大值之间的比例
  let ratio = (currentValue - minValue) / (maxValue - minValue);

  // 根据比例计算颜色数组中的索引
  let colorIndex = Math.floor(ratio * (colorArray.length - 1));

  // 返回对应的颜色
  return colorArray[colorIndex];
};

export { vectorSynthesisFun, calculateNewCoordinates, getColorForValue };
